import pick from 'lodash/pick';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { Highlight, ParentDocument, ReducedHighlight } from '../types';
import type { DocumentTag } from '../types/tags';
import { isYouTubeUrl } from '../typeValidators';
import delay from '../utils/delay';
import { isDevOrTest, isMobile } from '../utils/environment';
import makeLogger from '../utils/makeLogger';
import type * as contentFrameMethods from './contentFramePortalGateInternalMethods';
import database from './database';
import { useHighlights } from './database/helperHooks';
import foregroundEventEmitter from './eventEmitter';
import { portalGate as portalGateToContentFrame } from './portalGates/contentFrame/to/reactNativeWebview';
import {
  createHighlight,
  createHighlightDocumentObject,
  deleteHighlights,
  mergeHighlights,
  updateHighlight,
} from './stateUpdaters/persistentStateUpdaters/documents/highlight';
import { setHighlightResizeState as setHighlightResizeStateInZustand } from './stateUpdaters/transientStateUpdaters/other';
import type { HighlightElement } from './types';
import type {
  ContentFrameSelectionInfo,
  WebContentFramePuppeteerProps,
} from './types/contentFramePuppeteerRelated';
import type { ContentFrameEventMessageArgumentMap } from './types/events';
import type { KnownHighlight, KnownHighlightsMap } from './types/knownHighlights';
import getHighlightElements from './utils/getHighlightElements';
import useHighlightResizeState from './utils/useHighlightResizeState';
import useLiveValueRef from './utils/useLiveValueRef';

const logger = makeLogger(__filename);

enum ContentFrameStatus {
  Destroyed = 'destroyed',
  Initialized = 'ready',
  Transitioning = 'transitioning',
}

type ContentFrameInfo = {
  status: ContentFrameStatus;
  stateAtTimeOfInitialization: {
    contentContainer?: HTMLElement;
    containerNodeSelector?: string;
    docId?: string;
  } | null;
};

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addContentFrameEventListener: (typeof portalGateToContentFrame)['on'];
  areHighlightsEqual: (a: ReducedHighlight, b: KnownHighlight) => boolean;
  excludePDFHighlights: boolean;
  getKnownHighlights: () => Promise<KnownHighlightsMap>;
  getLocationStringFromHighlight: (highlight: Highlight) => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  removeContentFrameEventListener: (typeof portalGateToContentFrame)['off'];
} & Omit<
  WebContentFramePuppeteerProps,
  'highlightIdToScrollTo' | 'highlightLocationToScrollTo' | 'setHighlightIdToScrollTo'
> &
  Pick<
    typeof contentFrameMethods,
    | 'addHighlights'
    | 'destroy'
    | 'enlargeHighlights'
    | 'getCurrentSelectionInfo'
    | 'getFocusableElementIndexForSelection'
    | 'getHighlightIdsInSelection'
    | 'getHighlightIdsInSelector'
    | 'getLastRightClickedImageSelectionInfo'
    | 'getLastRightClickedSelectionInfo'
    | 'getSelectionInfoForResizingHighlight'
    | 'getSelectionInfoFromSelector'
    | 'init'
    | 'onHighlightActivated'
    | 'onHighlightDeactivated'
    | 'removeHighlights'
    | 'scrollToAnchor'
    | 'updateIcons'
  >;

/*
  We need to check track of the status of the content frame. E.g. is it initialized?

  But why is it outside the component? Well, it's possible this component or its ancestors can be unmounted and
  re-mounted. When un/mounting, we set up and tear down the content frame. These operations are asynchronous and the
  component could un/mount while we're in the middle of running one.

  So one render could kick off an async operation and the next one needs to be able to handle the fact something is
  already running when it mounts, plus do handle the outcome once it completes.
*/
let contentFrameInfo: ContentFrameInfo = {
  stateAtTimeOfInitialization: null,
  status: ContentFrameStatus.Destroyed,
};

function setContentFrameInfo(info: ContentFrameInfo) {
  logger.debug('setContentFrameInfo', info);
  contentFrameInfo = info;
  // Sync status change to reactive equivalent inside the component
  foregroundEventEmitter.emit('content-frame-status-updated', info.status);
}

/*
  This is used to execute major asynchronous operations like setting up or tearing down the content frame. It handles
  updating the content frame status, etc. It is agnostic of / safe when it comes to component renders.
  When calling this, you tell it what the status, etc. should be once the operation completes and it handles everything,
  including the other intermediary states.
*/
function runContentFrameStatusChangingOperation<TFuncPromise extends Promise<void>>({
  contentFrameInfoOnSuccess,
  func,
}: {
  contentFrameInfoOnSuccess: ContentFrameInfo;
  func(): TFuncPromise;
}): TFuncPromise {
  const oldContentFrameInfo = { ...contentFrameInfo };
  const logMessagePrefix = `STATUS-CHANGER [${oldContentFrameInfo.status} -> ${contentFrameInfoOnSuccess.status}]:`;
  logger.debug(`${logMessagePrefix} start`);

  setContentFrameInfo({
    stateAtTimeOfInitialization: oldContentFrameInfo.stateAtTimeOfInitialization,
    status: ContentFrameStatus.Transitioning,
  });

  const promise = func();

  promise
    .then(() => {
      logger.debug(`${logMessagePrefix} success`, { contentFrameInfoOnSuccess });
      setContentFrameInfo(contentFrameInfoOnSuccess);
    })
    .catch((error) => {
      logger.debug(`${logMessagePrefix} error`, { error });
      setContentFrameInfo(oldContentFrameInfo);
      throw error;
    });

  return promise;
}

const getHighlightElementsInDom = (container?: HTMLElement): HighlightElement[] => {
  if (!container) {
    return [];
  }

  return getHighlightElements({ container });
};

function makeHighlightDataFromSelectionInfo(
  selectionInfo: ContentFrameSelectionInfo,
): Pick<Highlight, 'content' | 'html' | 'location' | 'markdown' | 'offset'> {
  if (selectionInfo.text.trim() !== selectionInfo.text) {
    logger.debug('Highlight content is not trimmed', { text: selectionInfo.text });
  }
  return {
    ...pick(selectionInfo, ['html', 'location', 'markdown', 'offset']),
    content: selectionInfo.text,
  };
}

const defaultExport = React.memo(function BaseContentFramePuppeteer({
  addContentFrameEventListener,
  addHighlights,
  areHighlightsEqual,
  canInitWithoutDocId,
  containerNodeSelector,
  contentContainer,
  destroy: destroyArgument,
  docId,
  enlargeHighlights,
  excludePDFHighlights,
  getCurrentSelectionInfo,
  getFocusableElementIndexForSelection,
  getHighlightIdsInSelection,
  getHighlightIdsInSelector: getHighlightIdsInSelectorArgument,
  getKnownHighlights,
  getLastRightClickedImageSelectionInfo,
  getLastRightClickedSelectionInfo,
  getLocationStringFromHighlight,
  getSelectionInfoForResizingHighlight,
  getSelectionInfoFromSelector,
  init,
  isActive,
  isAutoHighlightingEnabled,
  mustUseContentContainerArgument,
  onFailedExtensionOrYouTubeHighlightIdsUpdated,
  onHighlightActivated,
  onHighlightDeactivated,
  onHighlightElementsChanged,
  preCreateOrResizeHighlight,
  removeContentFrameEventListener,
  removeHighlights,
  shouldBlockContentFrameInitialization,
  shouldProgressivelyRenderHighlights,
  sourceUrl,
  updateIcons,
  areDatabaseHooksEnabled = isActive,
  canShowHighlights = isActive,
}: Props): null {
  // We need a reactive copy of `contentFrameInfo.status`
  const [contentFrameStatus, onContentFrameStatusUpdated] = useState(contentFrameInfo.status);
  useEffect(() => {
    foregroundEventEmitter.on('content-frame-status-updated', onContentFrameStatusUpdated);
    return () => {
      foregroundEventEmitter.off('content-frame-status-updated', onContentFrameStatusUpdated);
    };
  }, [onContentFrameStatusUpdated]);

  const highlights = useHighlights({
    excludePDFHighlights,
    isEnabled: areDatabaseHooksEnabled,
    parentDocId: docId,
  });
  const highlightIdsString = useMemo(
    () =>
      highlights.map((highlight) => highlight.id + getLocationStringFromHighlight(highlight)).join('-'),
    [getLocationStringFromHighlight, highlights],
  );

  /*
    The source of truth for this is inside the content frame.
    Changes are only made inside and those changes are synced out to Zustand via the
    `highlight-resize-state-updated` event.
  */
  const highlightResizeStateRef = useLiveValueRef(useHighlightResizeState());

  /*
    When there is any change to the list of highlights, we re-render any highlight that doesn't match the expectation
    (e.g. html). This ref is used to limit the number of re-renders.
  */
  const highlightsRerenderedDueToMismatchesRef = useRef<{ [id: string]: number }>({});

  const [
    failedExtensionOrYouTubeHighlightsHighlightIds,
    setFailedExtensionOrYouTubeHighlightsHighlightIds,
  ] = useState<Highlight['id'][]>([]);
  useEffect(() => {
    onFailedExtensionOrYouTubeHighlightIdsUpdated?.(failedExtensionOrYouTubeHighlightsHighlightIds);

    return () => {
      onFailedExtensionOrYouTubeHighlightIdsUpdated?.([]);
    };
  }, [failedExtensionOrYouTubeHighlightsHighlightIds, onFailedExtensionOrYouTubeHighlightIdsUpdated]);

  const updateHighlightIcons = useCallback(
    async (highlightsArgument: Highlight[]): Promise<void> => {
      await Promise.all(
        highlightsArgument.map((highlight) =>
          updateIcons(highlight.id, {
            note: Boolean(highlight.children.length),
            tag: Boolean(Object.keys(highlight.tags ?? {}).length),
          }),
        ),
      );
    },
    [updateIcons],
  );

  useEffect(() => {
    if (contentFrameInfo.status !== ContentFrameStatus.Initialized) {
      return;
    }
    updateHighlightIcons(highlights);
  }, [docId, highlights, updateHighlightIcons]);

  const containerNodeSelectorRef = useLiveValueRef(containerNodeSelector);

  const destroy = useCallback(
    async function destroy() {
      if (contentFrameInfo.status !== ContentFrameStatus.Initialized) {
        return;
      }

      highlightsRerenderedDueToMismatchesRef.current = {};

      setFailedExtensionOrYouTubeHighlightsHighlightIds([]);
      await runContentFrameStatusChangingOperation({
        contentFrameInfoOnSuccess: {
          status: ContentFrameStatus.Destroyed,
          stateAtTimeOfInitialization: null,
        },
        func: destroyArgument,
      });
    },
    [destroyArgument],
  );

  const destroyWithTimeCheck = useCallback(
    async function destroyWithTimeCheck() {
      const delayAmount = 500;
      let hasDestroyFinished = false;

      if (isDevOrTest && !isMobile) {
        // eslint-disable-next-line promise/catch-or-return
        delay(delayAmount).then(() => {
          if (hasDestroyFinished) {
            return;
          }
          throw new Error(`Content frame destroy hasn't finished after ${delayAmount}`);
        });
      }

      const result = await destroy();
      hasDestroyFinished = true;
      return result;
    },
    [destroy],
  );

  // Run destroy when the compount is unmounted
  useEffect(
    () =>
      function onDismountHook() {
        logger.debug('Dismount');
        destroyWithTimeCheck();
        if (isMobile) {
          // The webview gets destroyed and recreated, we lose the ongoing promise, etc.
          setContentFrameInfo({
            stateAtTimeOfInitialization: null,
            status: ContentFrameStatus.Destroyed,
          });
        }
      },
    // destroyWithTimeCheck is missing to prevent unnecessarily runs
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // Validation
  useEffect(
    () =>
      function validator() {
        if (
          isActive &&
          contentContainer &&
          containerNodeSelector &&
          !contentContainer.matches(containerNodeSelector)
        ) {
          throw new Error('contentContainer does not match containerNodeSelector');
        }
      },
    [contentContainer, containerNodeSelector, isActive],
  );

  // Init contentFrame (this also destroys first if needed)
  useEffect(
    function contentFrameInitializerHook() {
      (async () => {
        const logMessagePrefix = `contentFrameInitializerHook [${Math.round(Math.random() * 9999)}]`;

        /*
        In the web app, if you leave a document and come back, things maybe could get out of sync.
        We do a safety check here that the containerNode that the ContentFrame's Renderer is holding
        is in fact the correct one. If not, we run the cleanup function first and re-init.
      */
        let reasonToDestroy: string | undefined; // For debugging
        if (contentFrameInfo.status === ContentFrameStatus.Initialized) {
          if (
            contentContainer &&
            contentFrameInfo.stateAtTimeOfInitialization?.contentContainer &&
            !contentContainer.isEqualNode(contentFrameInfo.stateAtTimeOfInitialization.contentContainer)
          ) {
            reasonToDestroy = 'contentContainer has changed';
          } else if (
            containerNodeSelector &&
            containerNodeSelector !== contentFrameInfo.stateAtTimeOfInitialization?.containerNodeSelector
          ) {
            reasonToDestroy = 'containerNodeSelector has changed';
          } else if (docId !== contentFrameInfo.stateAtTimeOfInitialization?.docId) {
            reasonToDestroy = 'docId has changed';
          }

          if (reasonToDestroy) {
            logger.debug(`${logMessagePrefix} destroying first...`, {
              contentContainer,
              contentFrameInfo,
              containerNodeSelector,
              docId,
              reasonToDestroy,
            });
            await destroyWithTimeCheck();
            logger.debug(`${logMessagePrefix} destroying first... DONE`, { reasonToDestroy });
          }
        }

        let reasonToExitEarly: string | undefined; // For debugging

        if (contentFrameInfo.status === ContentFrameStatus.Transitioning) {
          reasonToExitEarly = 'content frame is transitioning';
        } else if (contentFrameInfo.status === ContentFrameStatus.Initialized) {
          reasonToExitEarly = 'content frame is already initialized';
        } else if (!isActive) {
          reasonToExitEarly = 'inactive';
        } else if (!docId && !canInitWithoutDocId) {
          reasonToExitEarly = 'no document ID';
        } else if (shouldBlockContentFrameInitialization) {
          reasonToExitEarly = 'shouldBlockContentFrameInitialization';
        } else if (mustUseContentContainerArgument && !contentContainer) {
          reasonToExitEarly = 'no content container';
        } else if (containerNodeSelector !== containerNodeSelectorRef.current) {
          reasonToExitEarly = 'container node selector has changed during the run of this hook';
        }
        if (reasonToExitEarly) {
          if (reasonToDestroy) {
            logger.warn(`${logMessagePrefix} exiting early after destroying`, {
              reasonToDestroy,
              reasonToExitEarly,
            });
          } else {
            logger.debug(`${logMessagePrefix} exiting early`, {
              reasonToDestroy,
              reasonToExitEarly,
            });
          }
          return;
        }

        await runContentFrameStatusChangingOperation({
          contentFrameInfoOnSuccess: {
            stateAtTimeOfInitialization: {
              contentContainer,
              containerNodeSelector,
              docId,
            },
            status: ContentFrameStatus.Initialized,
          },
          func: () =>
            init({
              containerNodeSelector,
              docId,
              documentUrl: sourceUrl,
              shouldProgressivelyRender: Boolean(shouldProgressivelyRenderHighlights),
            }),
        });

        foregroundEventEmitter.emit('content-frame:initialized');
      })();
    },
    [
      canInitWithoutDocId,
      containerNodeSelector,
      containerNodeSelectorRef,
      contentContainer,
      contentFrameStatus,
      destroyWithTimeCheck,
      docId,
      init,
      isActive,
      mustUseContentContainerArgument,
      shouldBlockContentFrameInitialization,
      shouldProgressivelyRenderHighlights,
      sourceUrl,
    ],
  );

  // Clear highlights when inactive
  useEffect(
    function clearHighlightsWhenInactiveHook() {
      if (!canShowHighlights) {
        removeHighlights('all');
      }
    },
    [canShowHighlights, contentContainer, docId, removeHighlights],
  );

  // Adding/updating highlights
  useEffect(
    () => {
      let wasCleanUpCalled = false;

      (async function () {
        if (!canShowHighlights || contentFrameInfo.status !== ContentFrameStatus.Initialized) {
          return;
        }

        try {
          // NOTE: these are incoming highlights, i.e. the latest updates
          const highlightIds = highlights.map(({ id }) => id);
          const knownHighlights = await getKnownHighlights();
          if (wasCleanUpCalled || contentFrameInfo.status !== ContentFrameStatus.Initialized) {
            return;
          }

          const newHighlights: Highlight[] = [];
          const removedHighlightIds: Highlight['id'][] = Object.keys(knownHighlights).filter(
            (id) => !highlightIds.includes(id),
          );
          const updatedHighlights: Highlight[] = []; // doesn't include enlarged highlights
          const enlargedHighlights: Highlight[] = [];

          for (const removedHighlightId of removedHighlightIds) {
            delete highlightsRerenderedDueToMismatchesRef.current[removedHighlightId];
          }

          for (const highlight of highlights) {
            const knownHighlight = knownHighlights[highlight.id];
            if (knownHighlight) {
              if (areHighlightsEqual(highlight, knownHighlight)) {
                continue; // Don't do anything with this highlight
              }

              // The highlight from state does not match the rendered highlight...

              if (highlight.location && highlight.location === knownHighlight.location?.trim()) {
                if (highlightsRerenderedDueToMismatchesRef.current[highlight.id] > 1) {
                  // Mismatch re-render limit reached, do not re-render until there is a real change in future
                  continue;
                }
                highlightsRerenderedDueToMismatchesRef.current[highlight.id] =
                  (highlightsRerenderedDueToMismatchesRef.current[highlight.id] || 0) + 1;
              } else {
                // It has been updated in a significant way
                delete highlightsRerenderedDueToMismatchesRef.current[highlight.id];

                if ((highlight.content || '').length > (knownHighlight.content || '').length) {
                  enlargedHighlights.push(highlight);
                  continue;
                }
              }

              // Re-render (either due to mismatch or actual update)
              updatedHighlights.push(highlight);
              continue;
            }

            // Highlight is not already rendered
            newHighlights.push(highlight);
          }

          if (updatedHighlights.length > 1) {
            logger.warn(
              'More than one highlight has been updated in state vs what was last added in content frame. There may be something wrong here; e.g. the comparison is producing false negatives',
            );
          }

          // Updated highlights are destroyed & recreated
          removedHighlightIds.push(...updatedHighlights.map(({ id }) => id));
          newHighlights.push(...updatedHighlights);

          if (removedHighlightIds.length) {
            await removeHighlights(removedHighlightIds);
            if (wasCleanUpCalled || contentFrameInfo.status !== ContentFrameStatus.Initialized) {
              return;
            }
          }

          if (enlargedHighlights.length) {
            await enlargeHighlights(enlargedHighlights);
            if (wasCleanUpCalled || contentFrameInfo.status !== ContentFrameStatus.Initialized) {
              return;
            }
          }

          if (newHighlights.length) {
            const failedHighlightIds = await addHighlights(newHighlights);
            // For YouTube transcripts cleaned by ChatGPT. Capitalization changes may cause highlights to fail
            // In those cases, we want to show the "failed to render highlights" banner.
            if (isYouTubeUrl(sourceUrl)) {
              setFailedExtensionOrYouTubeHighlightsHighlightIds(failedHighlightIds);
            } else {
              setFailedExtensionOrYouTubeHighlightsHighlightIds(
                failedHighlightIds.filter(
                  (id) =>
                    newHighlights.find((highlight) => highlight.id === id)?.source ===
                    'Readwise web highlighter',
                ),
              );
            }
            if (wasCleanUpCalled || contentFrameInfo.status !== ContentFrameStatus.Initialized) {
              return;
            }
          }

          await updateHighlightIcons(highlights);
        } catch (e) {
          if (e instanceof Error && e.message !== 'Not initialized') {
            throw e;
          }
        }
      })();

      return () => {
        wasCleanUpCalled = true;
      };
    },
    // highlights is omitted on purpose (highlightIdsString covers it)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      addHighlights,
      areHighlightsEqual,
      canShowHighlights,
      contentContainer,
      contentFrameInfo.status,
      docId,
      enlargeHighlights,
      getKnownHighlights,
      highlightIdsString,
      removeHighlights,
      updateHighlightIcons,
    ],
  );

  const makeHighlightSourceSpecificData = useCallback(async (): Promise<
    Highlight['source_specific_data'] | undefined
  > => {
    const highlightableElementIndex = await getFocusableElementIndexForSelection();
    if (highlightableElementIndex !== undefined && highlightableElementIndex >= 0) {
      return { paragraph_index: highlightableElementIndex };
    }
  }, [getFocusableElementIndexForSelection]);

  useEffect(() => {
    const getHighlightIdsInSelector = (
      ...args: Parameters<Props['getHighlightIdsInSelector']>
    ): ReturnType<Props['getHighlightIdsInSelector']> => getHighlightIdsInSelectorArgument(...args);

    // If no selector is given, the current selection is highlighted instead
    const highlight = async ({
      selector,
      selectionInfo: selectionInfoArgument,
      collisionOutcome = 'merge',
      userInteraction = 'unknown',
    }: {
      selector?: string;
      selectionInfo?: Awaited<ReturnType<typeof getCurrentSelectionInfo>>;
      collisionOutcome?: 'merge' | 'remove';
      userInteraction?: Parameters<typeof createHighlight>[2]['userInteraction'];
    } = {}): Promise<Highlight['id']> => {
      preCreateOrResizeHighlight?.();

      let getSelectionInfo = getCurrentSelectionInfo;
      let getHighlightIds = getHighlightIdsInSelection;
      let selectionInfoBeingUsed = 'current selection';

      if (selectionInfoArgument) {
        selectionInfoBeingUsed = 'argument';
        getSelectionInfo = async () => selectionInfoArgument;
      } else if (selector) {
        selectionInfoBeingUsed = 'selector';
        getSelectionInfo = (options = {}) => getSelectionInfoFromSelector({ selector, ...options });
        getHighlightIds = () => getHighlightIdsInSelector({ selector });
      }

      const highlightIdsInSelection = await getHighlightIds({
        serializedLocationToUseIfThereIsNoSelection: selectionInfoArgument?.location,
      });
      if (highlightIdsInSelection.length) {
        if (collisionOutcome === 'remove') {
          await deleteHighlights(highlightIdsInSelection, { userInteraction });
          return highlightIdsInSelection[0];
        }

        // Merge with existing...

        const expandedSelectionInfo = await getSelectionInfo({
          shouldExpandToHighlightBounds: true,
        });

        if (!expandedSelectionInfo) {
          // Would be surprising if this happened
          throw new Error('No selection info');
        }
        const [remainingHighlightId, ...otherHighlightIds] = highlightIdsInSelection;
        await mergeHighlights(
          {
            otherHighlightIds,
            remainingHighlightDetails: {
              ...makeHighlightDataFromSelectionInfo(expandedSelectionInfo),
              id: remainingHighlightId,
              source_specific_data: await makeHighlightSourceSpecificData(),
            },
            sourceUrl: sourceUrl || '',
          },
          { userInteraction },
        );
        return remainingHighlightId;
      }

      // New standalone highlight...

      const selectionInfo = await getSelectionInfo({});

      if (!selectionInfo) {
        throw new Error(`No selectionInfo (from ${selectionInfoBeingUsed})`);
      }

      if (!docId) {
        throw new Error('No document ID for article');
      }

      /*
        The following was slow on mobile. It took way too long between the user's finger being released and the highlight being drawn
        on screen. The UI was 100% state-driven but it took too long to add the highlight document to the state and for that to
        propagate. Now, we trigger the render optimistically and add the document to the state after a delay; i.e. we give the render
        a headstart before touching state.
      */

      const highlightData = createHighlightDocumentObject({
        ...makeHighlightDataFromSelectionInfo(selectionInfo),
        parent: docId,
        source_specific_data: await makeHighlightSourceSpecificData(),
      });
      foregroundEventEmitter.emit('content-frame:highlight-about-to-be-created', {
        id: highlightData.id,
      });

      const addPromise = addHighlights([highlightData], true);

      // eslint-disable-next-line promise/catch-or-return
      await Promise.race([delay(50), addPromise]);

      try {
        await createHighlight(highlightData, {}, { userInteraction });
      } catch (error) {
        logger.error('error', { error });

        // Remove from DOM:
        try {
          await removeHighlights([highlightData.id]);
        } catch (e) {
          // Ignore
        }
      }

      return highlightData.id;
    };

    async function resizeHighlight({
      highlightId,
      userInteraction = 'unknown',
    }: {
      highlightId: Highlight['id'];
      userInteraction?: Parameters<typeof createHighlight>[2]['userInteraction'];
    }): Promise<void> {
      preCreateOrResizeHighlight?.();

      const getSelectionInfo = portalGateToContentFrame.methods.getSelectionInfoForResizingHighlight;
      const selectionInfoBeingUsed = 'current selection';

      try {
        const highlightIdsInSelection = await getHighlightIdsInSelection({
          serializedLocationToUseIfThereIsNoSelection: undefined,
        });
        const otherHighlightIdsInSelection = highlightIdsInSelection.filter((id) => id !== highlightId);
        if (otherHighlightIdsInSelection.length) {
          // Merge with existing...

          const expandedSelectionInfo = await getSelectionInfo({
            shouldExpandToHighlightBounds: true,
            shouldIgnoreHighlightResizeStateWhenTrimming: true,
          });

          if (!expandedSelectionInfo) {
            // Would be surprising if this happened
            throw new Error(`No selection info (using ${selectionInfoBeingUsed})`);
          }

          await mergeHighlights(
            {
              otherHighlightIds: otherHighlightIdsInSelection,
              remainingHighlightDetails: {
                ...makeHighlightDataFromSelectionInfo(expandedSelectionInfo),
                id: highlightId,
                source_specific_data: await makeHighlightSourceSpecificData(),
              },
              sourceUrl: sourceUrl || '',
            },
            { userInteraction },
          );

          return;
        }

        // Just resize the highlight itself and change nothing else...

        const selectionInfo = await getSelectionInfo({
          shouldExpandToHighlightBounds: false,
          shouldClearSelection: true,
          shouldIgnoreHighlightResizeStateWhenTrimming: true,
        });
        if (!selectionInfo) {
          throw new Error('No selectionInfo');
        }

        if (!docId) {
          throw new Error('No document ID for article');
        }

        const oldHighlight = await database.collections.documents.findOne<Highlight>(highlightId);
        if (!oldHighlight) {
          throw new Error("Can't get existing highlight from database");
        }

        const highlightDataUpdates = {
          ...makeHighlightDataFromSelectionInfo(selectionInfo),
          source_specific_data: await makeHighlightSourceSpecificData(),
        };

        // Skip if the highlight isn't resized (after selection was trimmed)
        if (oldHighlight.location === highlightDataUpdates.location) {
          await portalGateToContentFrame.methods.resetHighlightResizeStateIfWaitingForRender(
            highlightId,
          );
          return;
        }

        await updateHighlight(highlightId, highlightDataUpdates);
      } catch (error) {
        logger.error('resizeHighlight errored', { error });
        await portalGateToContentFrame.methods.resetHighlightResizeStateIfWaitingForRender(highlightId);
        throw error;
      }
    }

    async function highlightMatchingTextBlocks({
      highlights,
      tags,
      userInteraction = 'unknown',
    }: {
      highlights?: string[];
      tags?: { [key: string]: DocumentTag };
      userInteraction?: Parameters<typeof createHighlight>[2]['userInteraction'];
    } = {}) {
      if (!highlights || highlights.length === 0) {
        return [];
      }

      if (!docId) {
        throw new Error('No document ID for article');
      }

      const remainingHighlightIds: Highlight['id'][] = [];
      const highlightsData: (Highlight & { parent: ParentDocument['id'] })[] = [];

      // loop over all text fragments and try to create a selection
      for (const highlight of highlights) {
        try {
          await portalGateToContentFrame.methods.selectMatchingText(highlight);
        } catch (error) {
          logger.warn('Text selection in content frame failed.', { error });
          continue;
        }

        const selectionInfo = await getCurrentSelectionInfo({ shouldExpandToHighlightBounds: true });
        if (!selectionInfo) {
          throw new Error(`No selectionInfo`);
        }

        // merge with existing highlights
        const highlightIdsInSelection = await getHighlightIdsInSelection({
          serializedLocationToUseIfThereIsNoSelection: selectionInfo?.location,
        });

        if (highlightIdsInSelection.length) {
          if (!selectionInfo) {
            // Would be surprising if this happened
            throw new Error('No selection info');
          }
          const [remainingHighlightId, ...otherHighlightIds] = highlightIdsInSelection;
          await mergeHighlights(
            {
              otherHighlightIds,
              remainingHighlightDetails: {
                content: selectionInfo.text,
                html: selectionInfo.html,
                id: remainingHighlightId,
                location: selectionInfo.location,
                offset: selectionInfo.offset,
              },
              sourceUrl: sourceUrl || '',
            },
            { userInteraction },
          );

          remainingHighlightIds.push(remainingHighlightId);
          continue;
        }

        // New standalone highlight...
        highlightsData.push(
          createHighlightDocumentObject({
            ...makeHighlightDataFromSelectionInfo(selectionInfo),
            parent: docId,
            source_specific_data: {
              generated: true,
            },
            tags,
          }),
        );
      }

      await addHighlights(highlightsData);
      for (const highlight of highlightsData) {
        try {
          await createHighlight(highlight, {}, { userInteraction });
        } catch (error) {
          logger.error('error', { error });

          // Remove from DOM:
          try {
            await removeHighlights([highlight.id]);
          } catch (e) {
            // Ignore
          }
        }
      }
    }

    const getContentFromHighlightId = async (id: Highlight['id']): Promise<string> => {
      const knownHighlights = await getKnownHighlights();
      return knownHighlights[id]?.content || '';
    };

    foregroundEventEmitter.on('getContentFromHighlightId', getContentFromHighlightId);
    foregroundEventEmitter.on('getHighlightIdsInSelector', getHighlightIdsInSelector);
    foregroundEventEmitter.on('highlight', highlight);
    foregroundEventEmitter.on('highlightMatchingTextBlocks', highlightMatchingTextBlocks);
    addContentFrameEventListener('create-highlight', highlight);

    const highlightLastRightClickedImage = async () => {
      preCreateOrResizeHighlight?.();
      const selectionInfo = await getLastRightClickedImageSelectionInfo();
      if (!selectionInfo) {
        return;
      }

      if (!docId) {
        throw new Error('No document ID for article');
      }

      await createHighlight(
        {
          ...makeHighlightDataFromSelectionInfo(selectionInfo),
          parent: docId,
        },
        {},
        {
          userInteraction: 'context-menu',
        },
      );
    };
    foregroundEventEmitter.on('highlightLastRightClickedImage', highlightLastRightClickedImage);

    const highlightLastRightClickedSelection = async () => {
      preCreateOrResizeHighlight?.();

      const selectionInfo = await getLastRightClickedSelectionInfo();
      if (!selectionInfo) {
        logger.warn(
          'highlightLastRightClickedSelection called but there is no lastRightClickedSelectionInfo',
        );
        return;
      }

      if (!docId) {
        throw new Error('No document ID for article');
      }

      await createHighlight(
        {
          ...makeHighlightDataFromSelectionInfo(selectionInfo),
          parent: docId,
        },
        {},
        {
          userInteraction: 'context-menu',
        },
      );
    };
    foregroundEventEmitter.on('highlightLastRightClickedSelection', highlightLastRightClickedSelection);

    const onValidSelectionCompleted = async ({
      finalEventName,
      isAltPressed,
      selectionInfoExpandedToHighlightBounds,
    }: ContentFrameEventMessageArgumentMap['valid-selection-completed']) => {
      logger.debug('onValidSelectionCompleted', {
        finalEventName,
      });

      if (!canShowHighlights || contentFrameInfo.status !== ContentFrameStatus.Initialized) {
        return;
      }

      if (highlightResizeStateRef.current.status !== 'inactive') {
        portalGateToContentFrame.methods.onResizeHighlightUserInteractionFinished();
        await resizeHighlight({
          highlightId: highlightResizeStateRef.current.idOfHighlightBeingResized,
        });
        return;
      }

      if (isAutoHighlightingEnabled ? !isAltPressed : isAltPressed) {
        return highlight({ selectionInfo: selectionInfoExpandedToHighlightBounds });
      }
    };
    addContentFrameEventListener('valid-selection-completed', onValidSelectionCompleted);

    foregroundEventEmitter.on('highlight-activated', onHighlightActivated);
    foregroundEventEmitter.on('highlight-deactivated', onHighlightDeactivated);

    const onContentMoved = () => foregroundEventEmitter.emit('content-frame:content-moved');
    addContentFrameEventListener('content-moved', onContentMoved);

    const onContentFrameHighlightElementsChanged = () => {
      if (!onHighlightElementsChanged) {
        return;
      }
      onHighlightElementsChanged(getHighlightElementsInDom(contentContainer));
    };
    addContentFrameEventListener('highlight-elements-changed', onContentFrameHighlightElementsChanged);

    const onHighlightClicked = (details: unknown) =>
      foregroundEventEmitter.emit('content-frame:click', details);
    addContentFrameEventListener('highlight-clicked', onHighlightClicked);

    const onElementDoubleClicked = ({
      selectionInfo,
    }: { selectionInfo?: Awaited<ReturnType<typeof getCurrentSelectionInfo>> }) => {
      if (!isMobile) {
        return;
      }
      highlight({
        collisionOutcome: 'merge',
        selectionInfo,
        userInteraction: 'double-tap',
      });
    };
    addContentFrameEventListener('element-double-clicked', onElementDoubleClicked);

    addContentFrameEventListener('highlight-resize-state-updated', setHighlightResizeStateInZustand);

    return () => {
      foregroundEventEmitter.off('getContentFromHighlightId', getContentFromHighlightId);
      foregroundEventEmitter.off('highlight', highlight);
      foregroundEventEmitter.off('highlightMatchingTextBlocks', highlightMatchingTextBlocks);
      foregroundEventEmitter.off(
        'highlightLastRightClickedSelection',
        highlightLastRightClickedSelection,
      );
      foregroundEventEmitter.off('highlightLastRightClickedImage', highlightLastRightClickedImage);
      foregroundEventEmitter.off('highlight-activated', onHighlightActivated);
      foregroundEventEmitter.off('highlight-deactivated', onHighlightDeactivated);
      removeContentFrameEventListener('content-moved', onContentMoved);
      removeContentFrameEventListener('highlight-clicked', onHighlightClicked);
      removeContentFrameEventListener(
        'highlight-elements-changed',
        onContentFrameHighlightElementsChanged,
      );
      removeContentFrameEventListener(
        'highlight-resize-state-updated',
        setHighlightResizeStateInZustand,
      );
      removeContentFrameEventListener('element-double-clicked', onElementDoubleClicked);
      removeContentFrameEventListener('valid-selection-completed', onValidSelectionCompleted);
      removeContentFrameEventListener('create-highlight', highlight);
    };
  }, [
    addContentFrameEventListener,
    addHighlights,
    canShowHighlights,
    contentContainer,
    docId,
    getFocusableElementIndexForSelection,
    getKnownHighlights,
    getCurrentSelectionInfo,
    getHighlightIdsInSelection,
    getHighlightIdsInSelectorArgument,
    getLastRightClickedImageSelectionInfo,
    getLastRightClickedSelectionInfo,
    getSelectionInfoFromSelector,
    highlightResizeStateRef,
    isAutoHighlightingEnabled,
    makeHighlightSourceSpecificData,
    onHighlightActivated,
    onHighlightDeactivated,
    onHighlightElementsChanged,
    preCreateOrResizeHighlight,
    removeContentFrameEventListener,
    removeHighlights,
    sourceUrl,
  ]);

  return null;
});

// defaultExport.whyDidYouRender = {
//   trackHooks: true,
//   logOnDifferentValues: true,
// };

export default defaultExport;
