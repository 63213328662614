import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { isExtension } from 'shared/utils/environment';

import useDevOnlyHtmlIdValidation from '../../hooks/useDevOnlyHtmlIdValidation';

export default memo(function Portal({
  id,
  children,
}: {
  id: string;
  children: React.ReactNode;
}) {
  useDevOnlyHtmlIdValidation(id);

  const container = useMemo(
    () =>
      isExtension
        ? document.querySelector('readwise-tooltip-container')?.shadowRoot ?? document.body
        : document.body,
    [],
  );

  const destinationRef = useRef(container.querySelector(`#${id}`) ?? document.createElement('div'));
  const [destinationExistedFromBeginning] = useState(destinationRef.current.parentElement);

  useEffect(() => {
    if (!destinationExistedFromBeginning) {
      destinationRef.current.id = id;
      container.appendChild(destinationRef.current);
    }

    return () => {
      if (!destinationExistedFromBeginning && destinationRef.current.parentElement) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        destinationRef.current.parentElement.removeChild(destinationRef.current);
      }
    };
  }, [container, destinationExistedFromBeginning, id]);
  return createPortal(children, destinationRef.current);
});
