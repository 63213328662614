import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { hideModal } from 'shared/foreground/actions/modals';
import { globalState } from 'shared/foreground/models';
import {
  addRssIdsToRssFolder,
  createRssFolder,
} from 'shared/foreground/stateUpdaters/persistentStateUpdaters/feed';
import {
  setFeedIdsToAddInFolder,
  setFocusedDocumentId,
} from 'shared/foreground/stateUpdaters/transientStateUpdaters/other';

import useIsModalShown from '../../utils/useIsModalShown';
import Button from '../Button';
import styles from './AddRssFolderModal.module.css';
import Modal from './Modal';

export const modalId = 'add-rss-folder';

export default function AddRssFolderModal() {
  const feedIdsToAddInFolder = globalState(useCallback((state) => state.feedIdsToAddInFolder, []));
  const inputRef = React.createRef<HTMLInputElement>();
  const isShown = useIsModalShown(modalId);
  const history = useHistory();
  const [folderName, setFolderName] = useState('');
  const isCreationDisabled = !folderName.trim();

  useEffect(() => {
    if (isShown) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
      return;
    }

    setFolderName('');
    if (feedIdsToAddInFolder.length) {
      setFeedIdsToAddInFolder([], { userInteraction: 'click' });
    }
  }, [isShown, inputRef, feedIdsToAddInFolder.length]);

  const createNewFolder = useCallback(async () => {
    if (isCreationDisabled) {
      return;
    }

    const rssFolder = await createRssFolder(folderName, '', { userInteraction: 'click' });

    if (feedIdsToAddInFolder.length) {
      addRssIdsToRssFolder({
        rssFolderId: rssFolder.id,
        rssSourceIds: feedIdsToAddInFolder,
        options: { userInteraction: 'click' },
      });
    }

    hideModal({ id: modalId }, { userInteraction: 'click' });

    setFocusedDocumentId(null, { userInteraction: 'click' });

    if (!feedIdsToAddInFolder.length) {
      history.push(`/rss-folder/${rssFolder.filteredViewId}`);
    }
  }, [folderName, history, feedIdsToAddInFolder, isCreationDisabled]);

  const mainContent = (
    <>
      <div className={styles.modalContentWrapper}>
        <p>Use folders to organize and group feeds</p>
        <input
          id="search-field"
          ref={inputRef}
          type="text"
          value={folderName}
          autoComplete="off"
          onChange={(e) => setFolderName(e.target.value)}
          aria-labelledby="add-folder"
          placeholder="Untitled folder"
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter' || event.keyCode === 40) {
              createNewFolder();
            }
          }}
          autoFocus
        />
      </div>
    </>
  );

  const footerContent = (
    <>
      <Button
        variant="secondary"
        className={styles.cancelButton}
        onClick={() => hideModal({ id: modalId }, { userInteraction: 'click' })}
      >
        Cancel
      </Button>

      <Button variant="primary" onClick={createNewFolder} disabled={isCreationDisabled}>
        Create folder
      </Button>
    </>
  );

  return (
    <Modal
      id={modalId}
      footerContent={footerContent}
      mainContent={mainContent}
      title="Add new RSS folder"
    />
  );
}
