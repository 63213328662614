import { isDevOrTest } from 'shared/utils/environment';

import isValidHtmlId from '../utils/isValidHtmlId';

export default function useDevOnlyHtmlIdValidation(input: string) {
  if (!isDevOrTest) {
    return;
  }
  if (!isValidHtmlId(input)) {
    throw new Error('input is not a valid HTML ID');
  }
}
