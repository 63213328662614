import React from 'react';
import { Category } from 'shared/types';

import styles from './DocumentListCoverImage.module.css';
import usePlaceholderImage from './usePlaceholderImage';

export const DocumentListCoverImage = ({
  imageUrl,
  category,
}: { imageUrl: string | undefined; category: Category }): JSX.Element => {
  const classes = [styles.previewImage, 'previewImage'];
  const hasImageUrl = Boolean(imageUrl);
  if (!hasImageUrl) {
    classes.push(styles.placeholder);
  }
  const { placeholderIcon, placeholderBackgroundImage } = usePlaceholderImage(category, hasImageUrl);

  return (
    <div
      className={classes.join(' ')}
      style={{ backgroundImage: `url("${imageUrl}"), url("${placeholderBackgroundImage}")` }}
    >
      {placeholderIcon}
    </div>
  );
};
