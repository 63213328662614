import { updateState } from 'shared/foreground/models';
import { isNarrowScreenSize } from 'shared/foreground/stateHooks';
import { StateUpdateOptionsWithoutEventName } from 'shared/foreground/types';

import isReaderViewUrl from '../utils/isReaderViewUrl';
import { hideRightSidebar } from './sidebars';

export const toggleZenMode = async (options: StateUpdateOptionsWithoutEventName): Promise<void> => {
  await updateState(
    (state) => {
      const newZenModeValue = !state.zenModeEnabled;
      state.zenModeEnabled = newZenModeValue;

      if (isNarrowScreenSize(state.screenWidth)) {
        state.leftSidebarHiddenForNarrowScreen = newZenModeValue;
        state.rightSidebarHiddenForNarrowScreen = newZenModeValue;
      } else if (isReaderViewUrl()) {
        state.leftSidebarHiddenInReadingView = newZenModeValue;
        state.rightSidebarHiddenInReadingView = newZenModeValue;
      } else {
        state.client.rightSidebarHiddenInList = newZenModeValue;
      }
    },
    { ...options, isUndoable: false, eventName: 'zen-mode-toggled' },
  );
};
export const toggleDocumentMetadataOpen = (options: StateUpdateOptionsWithoutEventName): void => {
  // Make sure right panel is open
  hideRightSidebar(false, options);

  updateState(
    (state) => {
      state.isDocumentMetadataShown = !state.isDocumentMetadataShown;
    },
    { ...options, eventName: 'documents-metadata-opened' },
  );
};
