import React, { createContext, useCallback, useMemo } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import { createToast } from 'shared/foreground/toasts.platform';
import getSplitByDefaultValue from 'shared/foreground/utils/getSplitByDefaultValue';
import uploadFiles from 'shared/foreground/utils/uploadFiles';
import useDocumentLocations from 'shared/foreground/utils/useDocumentLocations';
import urlJoin from 'shared/utils/urlJoin';

import { getFilterViewQueryFromPathname, getSplitByKeyFromPathname } from '../utils/pathnameHelpers';
import styles from './FileDropzone.module.css';

interface ContextState {
  openFileDialog: () => void;
}

export const FileUploadContext = createContext({} as ContextState);

export const maxFileSizeMb = 500;

export default function FileDropzone({
  children,
  className = '',
}: { children: (JSX.Element | boolean)[]; className?: string }): JSX.Element {
  const history = useHistory();
  const maxFilesUploaded = 50;

  const documentLocations = useDocumentLocations();

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      for (const rejectedFile of rejectedFiles) {
        const name = rejectedFile.file.name || 'File';
        const errorCode = rejectedFile.errors[0].code;

        if (errorCode === 'too-many-files') {
          createToast({
            content: `Can't upload more than ${maxFilesUploaded} files at once`,
            category: 'error',
          });
          break;
        }
        switch (errorCode) {
          case 'file-invalid-type':
            createToast({ content: `${name} is not a PDF, EPUB or an OPML file.`, category: 'error' });
            break;

          case 'file-too-large':
            createToast({
              content: `${name} is too big. Max file size: ${maxFileSizeMb}MB`,
              category: 'error',
            });
            break;
          default:
            createToast({ content: 'An error occurred', category: 'error' });
            break;
        }
      }

      if (!acceptedFiles.length) {
        return;
      }

      await uploadFiles(acceptedFiles);

      const pathname = window.location.pathname;
      const currentSplitByKey = getSplitByKeyFromPathname(pathname);
      const query = getFilterViewQueryFromPathname(pathname);

      // Redirect to default split tab, so the user can see the uploaded file/s
      if (currentSplitByKey && query) {
        const splitByDefaultValue = getSplitByDefaultValue(currentSplitByKey, documentLocations);
        const newPathname = urlJoin([
          '/filter',
          encodeURIComponent(query),
          'split',
          currentSplitByKey,
          splitByDefaultValue,
        ]);
        history.push(newPathname);
      }
    },
    [documentLocations, history],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileDialog,
  } = useDropzone({
    onDrop,
    noClick: true,
    accept: ['application/pdf', '.opml', '.xml', '.epub', '.csv'],
    noKeyboard: true,
    maxFiles: maxFilesUploaded,
    maxSize: maxFileSizeMb * 1000000, // must be in bytes
  });

  const contextValue = useMemo(() => ({ openFileDialog }), [openFileDialog]);

  return (
    <div {...getRootProps({ className })}>
      <input autoComplete="off" {...getInputProps()} />
      {isDragActive && (
        <div className={styles.dropzone}>
          <h1>Upload files to Readwise</h1>
        </div>
      )}
      <FileUploadContext.Provider value={contextValue}>{children}</FileUploadContext.Provider>
    </div>
  );
}
