import React from 'react';
import { globalState } from 'shared/foreground/models';

import styles from './Ghost.module.css';

export default function Ghost() {
  const isRendered = globalState((state) => state.gptPromptLoading);

  return (
    <div className={`${styles.ghostContainer}  ${isRendered ? styles.show : ''}`}>
      <div className={`${styles.ghost}`}>
        <img className={styles.ghostBody} alt="ghostreader ghost body" src="/Ghost.svg" />
        <img className={styles.glasses} alt="ghost glasses" src="/Glasses.svg" />
      </div>
    </div>
  );
}
