import { AnyDocument } from '../../types';
import getServerBaseUrl from '../../utils/getServerBaseUrl.platform';
import requestWithAuth from '../../utils/requestWithAuth.platformIncludingExtension';
import { createToast } from '../toasts.platform';

export const sendToKindle = async (docId: AnyDocument['id']) => {
  const resp = await requestWithAuth(`${getServerBaseUrl()}/reader/api/send_to_kindle/${docId}`, {
    credentials: 'include',
    method: 'POST',
    mode: 'cors',
  });
  if (!resp.ok) {
    createToast({
      content: 'Failed to send to Kindle',
      category: 'error',
      duration: 5000,
    });
  } else {
    createToast({
      content: 'Sent to Kindle successfully',
      category: 'success',
      duration: 5000,
    });
  }
};

export const sendDigestToKindle = async () => {
  const resp = await requestWithAuth(`${getServerBaseUrl()}/reader/api/send_kindle_digest/`, {
    credentials: 'include',
    method: 'POST',
    mode: 'cors',
  });
  if (!resp.ok) {
    createToast({
      content: 'Failed to send digest to Kindle',
      category: 'error',
      duration: 5000,
    });
  } else {
    createToast({
      content: 'Sent digest to Kindle successfully',
      category: 'success',
      duration: 5000,
    });
  }
};
