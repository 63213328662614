import React from 'react';
import { BaseDocument, Category } from 'shared/types';

import styles from './StatusDot.module.css';
import Tooltip from './Tooltip';

type Props = {
  className?: string;
} & Pick<BaseDocument, 'category' | 'firstOpenedAt' | 'saved_at_history'>;

export default function StatusDot({
  category,
  className,
  firstOpenedAt,
  saved_at_history: savedAtHistory,
}: Props): JSX.Element | null {
  const classes = ['statusDot', styles.statusDot, className];
  let statusDotText;

  if ((savedAtHistory?.length ?? 0) > 1) {
    classes.push(styles.statusDotSavedMultipleTimes);
    statusDotText = 'Saved multiple times';
  } else if (!firstOpenedAt) {
    statusDotText = 'Unseen';
    if (category === Category.RSS) {
      classes.push(styles.statusDotFeedUnopened);
    } else {
      classes.push(styles.statusDotUnopened);
    }
  }

  if (!statusDotText) {
    return null;
  }

  return (
    <Tooltip content={statusDotText}>
      <div className={classes.filter(Boolean).join(' ')} />
    </Tooltip>
  );
}
