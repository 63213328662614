import { EventEmitter2 } from 'eventemitter2';
import React, { useCallback } from 'react';
import type { HighlightElement } from 'shared/foreground/types';

import getElementRect from '../utils/getElementRect';
import mergeRects from '../utils/mergeRects';
import HighlighterPopovers from './HighlighterPopovers';
import { Props as PopoverProps } from './Popovers/Popover';

type Props = {
  eventEmitter: EventEmitter2;
  highlightElements: HighlightElement[];
  contentWidth: number;
};

export default function PDFPopovers({
  eventEmitter,
  highlightElements,
  contentWidth,
}: Props): JSX.Element {
  const getBoundingClientRectForMargins: (references: PopoverProps['reference'][]) => DOMRect =
    useCallback((references) => {
      const highlightsRect = mergeRects(references.map(getElementRect));
      for (const ref of references) {
        if (!ref || !ref.parentNode) {
          continue;
        }
        const parentRect = getElementRect(ref.parentNode);
        highlightsRect.width = parentRect.width;
        highlightsRect.x = parentRect.x;
      }
      return highlightsRect;
    }, []);

  return (
    <HighlighterPopovers
      eventEmitter={eventEmitter}
      isAutoHighlightingAlwaysEnabled
      highlightElements={highlightElements}
      customClientRectGetter={getBoundingClientRectForMargins}
      containerNodeSelector="#pdf-viewer"
      customContentWidth={contentWidth}
      isPdfTron
    />
  );
}
