/*
  PLEASE do not add any more to this component. Keep it simply just for sanitizing the document content.
  No extra elements, no extra hooks, no listeners, etc.
*/
import React, { useEffect, useMemo } from 'react';
import forwardRef from 'shared/foreground/utils/forwardRef';
import { Category } from 'shared/types';
import { rwSanitizeHtml } from 'shared/utils/rwSanitizeHtml';

export type Props = {
  category: Category;
  content: string;
  onSanitized?(sanitizedHtml: string): void;
  originalEmailView?: boolean;
  showEnhancedYouTubeTranscript?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const defaultExport = React.memo(
  forwardRef<Props, HTMLDivElement>(function JustSanitizedDocumentContentOnly(
    { category, content, onSanitized, originalEmailView, showEnhancedYouTubeTranscript, ...props },
    ref,
  ): JSX.Element {
    const sanitizedHtml = useMemo(
      () => rwSanitizeHtml(content, category, originalEmailView, showEnhancedYouTubeTranscript),
      [category, content, originalEmailView, showEnhancedYouTubeTranscript],
    );
    useEffect(() => onSanitized?.(sanitizedHtml), [sanitizedHtml, onSanitized]);

    const mandatoryStylesClass =
      'mandatory-styles specific-1 specific-2 specific-3 specific-4 specific-5 specific-6 specific-7 specific-8 specific-9 specific-10';
    return (
      <div
        {...props}
        dangerouslySetInnerHTML={{
          __html: sanitizedHtml,
        }}
        className={`${props.className ?? ''} ${
          originalEmailView
            ? 'document-content--original-email'
            : `document-content ${mandatoryStylesClass}`
        }`}
        ref={ref}
      />
    );
  }),
);

// defaultExport.whyDidYouRender = {
//   trackHooks: true,
//   logOnDifferentValues: true,
// };

export default defaultExport;
