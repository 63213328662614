import React, { useCallback, useEffect, useMemo, useState } from 'react';
import eventEmitter from 'shared/foreground/eventEmitter';
import { Highlight } from 'shared/types';
import { ShortcutId } from 'shared/types/keyboardShortcuts';
import { notEmpty } from 'shared/typeValidators';
import getFormattedDateFromTimestamp from 'shared/utils/dates/getFormattedDateFromTimestamp';

import { useShortcutsMap } from '../../utils/shortcuts';
import { DeleteHighlightDialog } from '../DeleteHighlightDialog';
import { getSeparatorOption } from './docOptions';
import { Dropdown, DropdownOption, DropdownOptionType } from './Dropdown';
import styles from './NotebookHighlightDropdown.module.css';

export default function NotebookHighlightDropdown({
  appendToDocumentBody,
  buttonClassName,
  copyHighlightText,
  copyNote,
  shareHighlight,
  invokeGhostreader,
  deleteHighlight,
  openEditNoteForm,
  openEditTagsForm,
  openInNotebookView,
  focusPseudoUnit,
  goToHighlight,
  highlight,
  note,
  trigger,
  isNotebookView,
}: {
  appendToDocumentBody?: boolean;
  buttonClassName?: string;
  copyHighlightText: () => void;
  copyNote: () => void;
  shareHighlight: () => void;
  invokeGhostreader?: () => void;
  deleteHighlight: () => void;
  openEditNoteForm?: () => void;
  openEditTagsForm?: () => void;
  openInNotebookView?: () => void;
  focusPseudoUnit?: () => void;
  goToHighlight?: () => void;
  highlight: Highlight;
  note?: string;
  trigger?: JSX.Element;
  isNotebookView?: boolean;
}) {
  let deleteHighlightDialog: JSX.Element | null = null;

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    eventEmitter.on(`set-highlight-menu-open:${highlight.id}`, setIsOpen);
    return () => {
      eventEmitter.off(`set-highlight-menu-open:${highlight.id}`, setIsOpen);
    };
  }, [highlight.id]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  if (isNotebookView) {
    deleteHighlightDialog = (
      <DeleteHighlightDialog
        isOpen={isDeleteDialogOpen}
        onCancel={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => deleteHighlight()}
      />
    );
  }

  const triggerDeleteHighlight = useCallback(() => {
    if (isNotebookView) {
      setIsDeleteDialogOpen(true);
    } else {
      deleteHighlight();
    }
  }, [deleteHighlight, isNotebookView]);

  const shortcutsMap = useShortcutsMap();

  const options = useMemo((): DropdownOption[] => {
    let shareOption: DropdownOption | undefined;

    if ('share' in navigator) {
      shareOption = {
        isDisabled: !highlight.content,
        name: 'Share highlight as text',
        onSelect: () => {
          if (!highlight.content) {
            return;
          }
          navigator.share({
            text: highlight.content,
          });
        },
        type: DropdownOptionType.Item,
      };
    }

    return [
      {
        isDisabled: !highlight.content,
        name: 'Copy highlight text',
        onSelect: copyHighlightText,
        shortcut: shortcutsMap[ShortcutId.CopyNotebookHighlightText],
        type: DropdownOptionType.Item,
      },
      {
        isDisabled: !note,
        name: 'Copy note',
        onSelect: copyNote,
        shortcut: shortcutsMap[ShortcutId.CopyNotebookHighlightNote],
        type: DropdownOptionType.Item,
      },
      isNotebookView ? null : getSeparatorOption(),
      isNotebookView
        ? null
        : {
            name: 'Edit tags',
            onSelect: openEditTagsForm,
            shortcut: shortcutsMap[ShortcutId.EditHighlightTagsInNotebook],
            type: DropdownOptionType.Item,
          },
      isNotebookView
        ? null
        : {
            name: 'Edit note',
            onSelect: openEditNoteForm,
            shortcut: shortcutsMap[ShortcutId.EditHighlightNoteInNotebook],
            type: DropdownOptionType.Item,
          },
      getSeparatorOption(),
      {
        name: 'View in document',
        onSelect: goToHighlight,
        shortcut: shortcutsMap[ShortcutId.ViewNotebookHighlightInDocument],
        type: DropdownOptionType.Item,
      },
      isNotebookView
        ? null
        : {
            name: 'View in notebook',
            onSelect: openInNotebookView,
            shortcut: shortcutsMap[ShortcutId.ViewHighlightInNotebookView],
            type: DropdownOptionType.Item,
          },
      shareOption,
      {
        isDisabled: !highlight.content,
        name: 'Share highlight as image',
        onSelect: shareHighlight,
        type: DropdownOptionType.Item,
      },
      isNotebookView ? getSeparatorOption() : null,
      isNotebookView
        ? {
            name: 'Invoke Ghostreader',
            onSelect: invokeGhostreader,
            shortcut: shortcutsMap[ShortcutId.Ghostreader],
            type: DropdownOptionType.Item,
          }
        : null,
      getSeparatorOption(),
      {
        className: styles.deleteOption,
        name: 'Delete',
        onSelect: () => triggerDeleteHighlight(),
        shortcut: shortcutsMap[ShortcutId.DeleteHighlightInNotebook],
        type: DropdownOptionType.Item,
      },
      getSeparatorOption(),
      {
        className: styles.savedAtOption,
        name: `Highlighted ${getFormattedDateFromTimestamp(highlight.saved_at)}`,
        type: DropdownOptionType.Item,
      },
    ].filter(notEmpty);
  }, [
    highlight.content,
    highlight.saved_at,
    copyHighlightText,
    shortcutsMap,
    note,
    copyNote,
    isNotebookView,
    openEditTagsForm,
    openEditNoteForm,
    goToHighlight,
    openInNotebookView,
    shareHighlight,
    invokeGhostreader,
    triggerDeleteHighlight,
  ]);

  return (
    <>
      <Dropdown
        appendToDocumentBody={appendToDocumentBody}
        contentAlignment="end"
        contentClassName={styles.content}
        defaultTriggerAttributes={{
          tabIndex: -1,
        }}
        isOpen={isOpen}
        onEscapeKeyDown={focusPseudoUnit}
        options={options}
        setIsOpen={setIsOpen}
        triggerClassName={[styles.button, buttonClassName].filter(Boolean).join(' ')}
        triggerShortcut={shortcutsMap[ShortcutId.OpenNotebookHighlightActionsDropdown]}
        triggerTooltipText="Highlight actions"
        trigger={trigger}
      />

      {deleteHighlightDialog}
    </>
  );
}
