import { useHistory } from 'react-router-dom';
import { openHighlightGptSubMenu } from 'shared/foreground/cmdPalette';
import foregroundEventEmitter from 'shared/foreground/eventEmitter';
import { updateState } from 'shared/foreground/models';
import { setQuoteshotModalOpen } from 'shared/foreground/stateUpdaters/clientStateUpdaters/quoteshot';
import {
  setFocusedDocumentId,
  setFocusedHighlightId,
} from 'shared/foreground/stateUpdaters/transientStateUpdaters/other';
import copyTextToClipboard from 'shared/foreground/utils/copyTextToClipboard';
import { type Highlight } from 'shared/types';
import urlJoin from 'shared/utils/urlJoin';

export const shareHighlightAsImage = async (highlight: Highlight) => {
  if (!highlight) {
    return;
  }
  await setQuoteshotModalOpen(true, highlight.id);
};

export const copyHighlightContent = (content: string | null | undefined) => {
  if (!content) {
    return;
  }
  copyTextToClipboard(content);
};

type HighlightClickEvent = {
  highlightElementClassName: string;
  iconClicked: 'note' | 'tag' | null;
  id: Highlight['id'];
};
export const triggerHighlightPopover = (
  iconClicked: HighlightClickEvent['iconClicked'],
  highlightId: Highlight['id'] | undefined,
) => {
  if (!highlightId) {
    return;
  }
  const event: HighlightClickEvent = {
    id: highlightId,
    iconClicked,
    highlightElementClassName: 'rw-pseudo-highlight',
  };
  foregroundEventEmitter.emit('content-frame:click', event);
};

export const HIGHLIGHT_HASH_PREFIX = 'hl__';
export function createHighlightHashId(highlightId: string): string {
  return `${HIGHLIGHT_HASH_PREFIX}${highlightId}`;
}
export function parseHighlightIdFromHash(hash: string): Highlight['id'] {
  return hash.replace(`#${HIGHLIGHT_HASH_PREFIX}`, '');
}

export function openSingleParentNotebookView(
  history: ReturnType<typeof useHistory>,
  parentDocId: string,
  highlightId?: Highlight['id'],
) {
  const hash = highlightId ? createHighlightHashId(highlightId) : '';
  history.push({
    state: {
      parentPath: history.location.pathname,
    },
    pathname: urlJoin(['/notebook/parent', parentDocId]),
    hash,
  });
}

export async function invokeGhostreader(highlight: Pick<Highlight, 'id' | 'parent' | 'markdown'>) {
  if (!highlight.id || !highlight.parent || !highlight.markdown) {
    return;
  }
  await updateState(
    (state) => {
      state.gptPrompt = {
        selection: highlight.markdown,
        expandedSelection: highlight.markdown,
        surroundingParagraphContents: highlight.markdown,
      };
    },
    { eventName: 'gpt-prompt-set-from-notebook-view', userInteraction: 'unknown' },
  );
  await setFocusedDocumentId(highlight.parent, {
    userInteraction: 'unknown',
    isUndoable: false,
    forceIfDropdownOpen: true,
  });
  await setFocusedHighlightId(highlight.id);
  await openHighlightGptSubMenu();
}
