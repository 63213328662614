import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useMemo, useState } from 'react';
import { MangoQuery } from 'rxdb';
import {
  removeRssItem,
  updateRssItemShowCountBadge,
} from 'shared/foreground/stateUpdaters/persistentStateUpdaters/feed';
import { setFeedIdToDelete } from 'shared/foreground/stateUpdaters/transientStateUpdaters/other';
import type { AnyDocument, RssItem } from 'shared/types';
import { ShortcutId } from 'shared/types/keyboardShortcuts';

import { useShortcutsMap } from '../../utils/shortcuts';
import Ellipsis from '../icons/16StrokeEllipsis';
import styles from './BuiltInCategoryViewDropDown.module.css';
import { getSeparatorOption } from './docOptions';
import { Dropdown, DropdownOptionType } from './Dropdown';
import {
  getDeleteViewOption,
  getEnableBundleLinkOption,
  getMarkAllAsSeenOption,
  getOpenBulkActionsOption,
  getSplitViewOption,
} from './DropdownMainHeader';

type RssItemDropdownOptionsProps = {
  rssItem: RssItem;
  documentIdsQuery?: MangoQuery<AnyDocument>;
  shortcutsMap: ReturnType<typeof useShortcutsMap>;
  isInSidebar?: boolean;
};

export const getRssItemDropdownOptions = ({
  rssItem,
  documentIdsQuery,
  shortcutsMap,
  isInSidebar = false,
}: RssItemDropdownOptionsProps) => {
  return [
    getSplitViewOption({
      isDisabled: isInSidebar,
      shortcut: shortcutsMap[ShortcutId.OpenSplitBySubMenu],
    }),
    getEnableBundleLinkOption({ view: undefined, documentIdsQuery, isDisabled: true }),
    getSeparatorOption(),
    getMarkAllAsSeenOption({ documentIdsQuery }),
    getOpenBulkActionsOption({
      isDisabled: isInSidebar,
      shortcut: shortcutsMap[ShortcutId.OpenBulkActionsSubMenu],
    }),
    getSeparatorOption(),
    {
      type: DropdownOptionType.Item,
      name: rssItem.rssFolderId ? 'Remove from folder' : 'Unpin from sidebar',
      onSelect: () => {
        removeRssItem(rssItem.id, { userInteraction: 'unknown' });
      },
    },
    {
      type: DropdownOptionType.Item,
      name: rssItem?.showCountBadge ? 'Hide count badge' : 'Show count badge',
      onSelect: () => {
        updateRssItemShowCountBadge(rssItem.id, !rssItem.showCountBadge, {
          userInteraction: 'keypress',
        });
      },
    },
    getSeparatorOption(),
    getDeleteViewOption({
      name: 'Remove subscription',
      onSelect: () => {
        setFeedIdToDelete(rssItem.rssSourceId);
      },
    }),
  ];
};

const Trigger = () => {
  return (
    <span>
      <Ellipsis />
    </span>
  );
};

export default React.memo(function RssItemDropdown({
  rssItem,
  onOpenChange,
  getViewMangoQueryWithoutSort,
}: {
  rssItem: RssItem;
  onOpenChange: (isOpen: boolean) => void;
  getViewMangoQueryWithoutSort: () => MangoQuery<AnyDocument> | undefined;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const shortcutsMap = useShortcutsMap();

  const TriggerElement = (
    <DropdownMenu.Trigger className={`${styles.dropdownTrigger} ${isOpen ? styles.isOpen : ''}`}>
      <Trigger />
    </DropdownMenu.Trigger>
  );

  const options = useMemo(() => {
    return getRssItemDropdownOptions({
      rssItem,
      documentIdsQuery: getViewMangoQueryWithoutSort(),
      shortcutsMap,
      isInSidebar: true,
    });
  }, [rssItem, getViewMangoQueryWithoutSort, shortcutsMap]);

  // Appending a lot of dropdowns to the body makes the transition to this view
  // a bit slow. So we are going to append it only after the user actually clicks it.

  if (isOpen) {
    return (
      <Dropdown
        trigger={TriggerElement}
        options={options}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onOpenChange={onOpenChange}
        contentClassName={styles.dropdownContent}
        appendToDocumentBody
      />
    );
  }

  return (
    <button
      type="button"
      className={styles.dropdownTrigger}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onOpenChange(true);
        setIsOpen(true);
      }}
    >
      <Trigger />
    </button>
  );
});
