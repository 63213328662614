import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import eventEmitter from 'shared/foreground/eventEmitter';
import { globalState } from 'shared/foreground/models';
import {
  useDocument,
  useFocusedDocumentListQuery,
  useGlobalTagsAsObject,
  useIsEmailOriginalView,
  useIsPDFViewAsHTML,
} from 'shared/foreground/stateHooks';
import { useIsTtsPlayerStateForThisDocument } from 'shared/foreground/stateHooks/tts';
import { setIsDocMoreActionsDropdownOpen } from 'shared/foreground/stateUpdaters/transientStateUpdaters/dropdowns';
import { AnyDocument, BaseDocument, FirstClassDocument } from 'shared/types';
import { ShortcutId } from 'shared/types/keyboardShortcuts';
import { isDocumentWithTTS } from 'shared/typeValidators';

import { isReaderViewUrl } from '../../utils/pathnameHelpers';
import { useShortcutsMap } from '../../utils/shortcuts';
import useLocation from '../../utils/useLocation';
import EditTagsPopover from '../Popovers/EditTagsPopover';
import {
  getAddDocNoteOption,
  getAddTagOption,
  getBumpOption,
  getCopyDocUrlOption,
  getDeleteDocOption,
  getEditMetadataOption,
  getMarkAboveAsSeenOption,
  getMarkAsSeenOrUnseenOption,
  getMarkBelowAsSeenOption,
  getOpenOriginalOption,
  getPauseOrPlayTtsOption,
  getResetReadingProgressOption,
  getSendToKindleOption,
  getSeparatorOption,
} from './docOptions';
import { Dropdown, DropdownOption } from './Dropdown';
import styles from './Dropdown.module.css';

export default function DocumentListDropdown({
  docId,
  triggerClassName = '',
}: { docId: AnyDocument['id']; triggerClassName?: string }) {
  const [doc] = useDocument<FirstClassDocument>(docId);
  const [globalTagsObject] = useGlobalTagsAsObject();
  const documentListQuery = useFocusedDocumentListQuery();

  const [isEditTagsPopoverShown, setIsEditTagsPopoverShown] = useState(false);
  const hideEditTagsPopover = useCallback(() => setIsEditTagsPopoverShown(false), []);
  const showEditTagsPopover = useCallback(() => setIsEditTagsPopoverShown(true), []);

  const history = useHistory();
  const navigateToIntegrations = useCallback(() => history.push('/integrations'), [history]);

  useEffect(() => {
    if (!doc?.id) {
      return;
    }

    const onEvent = (id: BaseDocument['id']) => {
      if (id !== doc?.id) {
        return;
      }
      showEditTagsPopover();
    };
    eventEmitter.on('open-document-list-edit-tags-popover', onEvent);

    return () => {
      eventEmitter.off('open-document-list-edit-tags-popover', onEvent);
    };
  }, [doc?.id, showEditTagsPopover]);

  const { pathname } = useLocation();
  const isDocMoreActionsDropdownOpen = globalState(
    useCallback((state) => state.isDocMoreActionsDropdownOpen, []),
  );
  const isMainDropdownOpen = isDocMoreActionsDropdownOpen && !isReaderViewUrl(pathname);
  const isDistributable = Boolean(doc && !doc.non_distributable);

  const triggerButtonRef = useRef<HTMLButtonElement>(null);
  const shortcutsMap = useShortcutsMap();

  const kindleEmailToSendDocuments = globalState(
    (state) => state.persistent.integrations?.kindle?.emailToSendDocuments,
  );
  const isKindleEmailSet = Boolean(kindleEmailToSendDocuments);

  const ttsPlayerStateForThisDocument = useIsTtsPlayerStateForThisDocument(doc?.id);
  const isOpenInPDFView = !useIsPDFViewAsHTML(docId);
  const isOpenInEmailView = useIsEmailOriginalView(docId);

  const options = useMemo(
    () =>
      doc
        ? ([
            getAddTagOption({ onSelect: showEditTagsPopover, shortcut: shortcutsMap[ShortcutId.Tags] }),
            getAddDocNoteOption({ shortcut: shortcutsMap[ShortcutId.AddDocNote] }),
            getBumpOption({ docId, shortcut: shortcutsMap[ShortcutId.Bump] }),
            doc &&
              isDocumentWithTTS(doc) &&
              getPauseOrPlayTtsOption({
                docId: doc.id,
                isOpen: false,
                ttsPlayerStateForThisDocument,
                isEmailView: isOpenInEmailView,
                isPDFView: isOpenInPDFView,
              }),
            getSeparatorOption(),
            getMarkAsSeenOrUnseenOption({ doc, shortcut: shortcutsMap[ShortcutId.ToggleDocAsOpened] }),
            getMarkAboveAsSeenOption({ docId, documentListQuery }),
            getMarkBelowAsSeenOption({ docId, documentListQuery }),
            getSeparatorOption(),
            getEditMetadataOption({ shortcut: shortcutsMap[ShortcutId.ShowDocMetadata] }),
            getResetReadingProgressOption({
              docId,
              shortcut: shortcutsMap[ShortcutId.ResetReadingProgress],
            }),
            isDistributable && getSeparatorOption(),
            isDistributable &&
              getOpenOriginalOption({ docId, shortcut: shortcutsMap[ShortcutId.OpenOriginalDoc] }),
            isDistributable && getCopyDocUrlOption({ docId, shortcut: shortcutsMap[ShortcutId.Share] }),
            getSendToKindleOption({
              docId,
              fileType: doc.category,
              isKindleEmailSet,
              onSelected: navigateToIntegrations,
            }),
            getSeparatorOption(),
            getDeleteDocOption({ docId, shortcut: shortcutsMap[ShortcutId.DeleteDocument] }),
          ].filter(Boolean) as DropdownOption[])
        : [],
    [
      doc,
      showEditTagsPopover,
      shortcutsMap,
      docId,
      isOpenInEmailView,
      isOpenInPDFView,
      ttsPlayerStateForThisDocument,
      documentListQuery,
      isDistributable,
      isKindleEmailSet,
      navigateToIntegrations,
    ],
  );

  return (
    <>
      <Dropdown
        appendToDocumentBody
        isOpen={isMainDropdownOpen}
        isSubDropdownOpen={isEditTagsPopoverShown}
        options={options}
        setIsOpen={setIsDocMoreActionsDropdownOpen}
        ref={triggerButtonRef}
        triggerClassName={triggerClassName}
        triggerShortcut={shortcutsMap[ShortcutId.ToggleDocMoreActions]}
        triggerTooltipText="More actions"
      />
      {doc && triggerButtonRef.current && (
        <EditTagsPopover
          allowFlip
          className={styles.editTagsPopover}
          doc={doc}
          globalTagsObject={globalTagsObject}
          hidePopover={hideEditTagsPopover}
          isShown={isEditTagsPopoverShown}
          onClick={(event) => event.stopPropagation()}
          popperOptions={{
            placement: 'bottom-start',
          }}
          portalDestinationElementId={`document-list-dropdown-${doc.id}`}
          ref={() => undefined}
          reference={triggerButtonRef.current}
          showPopover={showEditTagsPopover}
        />
      )}
    </>
  );
}
