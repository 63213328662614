import React, { useCallback } from 'react';
import { globalState } from 'shared/foreground/models';
import { useFocusedDocumentId } from 'shared/foreground/stateHooks/useFocusedDocument';
import { deleteDocumentForUser } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/documents/anyDocument';
import { closeDeleteDocumentDialog } from 'shared/foreground/stateUpdaters/transientStateUpdaters/dialogs';

import styles from './DeleteDocumentDialog.module.css';
import { Dialog } from './Dialog';

export const DeleteDocumentDialog = ({
  afterDeleteAction,
  shouldCreateToast,
}: {
  afterDeleteAction: () => void;
  shouldCreateToast?: boolean; // Defaults to true
}) => {
  const isOpen = globalState(useCallback((state) => state.isDeleteDocumentDialogOpen, []));
  const openDocId = useFocusedDocumentId();

  const onDiscard = useCallback(async () => {
    if (!openDocId) {
      return;
    }
    await deleteDocumentForUser(openDocId, { shouldCreateToast, userInteraction: 'click' });
    await closeDeleteDocumentDialog({ userInteraction: 'click' });
    afterDeleteAction();
  }, [afterDeleteAction, openDocId, shouldCreateToast]);

  const onCancel = useCallback(() => {
    closeDeleteDocumentDialog({ userInteraction: 'click' });
  }, []);

  if (isOpen) {
    return (
      <div className={styles.deleteDocumentDialog}>
        <Dialog
          id="delete-document"
          title="Delete Document?"
          subtitle="Deleting this document will also delete its annotations (notes and highlights)."
          actionTitle="Delete"
          redActionButton
          action={onDiscard}
          cancelAction={onCancel}
        />
      </div>
    );
  }
  return <></>;
};
