import React from 'react';
import contentFrame from 'shared/foreground/portalGates/contentFrame/to/singleProcess';
import { Highlight } from 'shared/types';

export const gptPromptInput = async (
  highlight: Highlight,
  focusTargetRef: React.RefObject<HTMLElement | undefined>,
  elementRef: React.RefObject<HTMLElement | undefined>,
): Promise<string | undefined> => {
  if (!elementRef.current) {
    return;
  }

  let focused = focusTargetRef.current;
  const highlightContents = highlight.content?.trim();

  // If you selected the whole header, let's go
  const selection = window.getSelection();
  const selectionText = selection?.toString()?.trim();
  // Browsers may choose the 'textnode' based on whether you single or
  // double-click to highlight the header
  const selectedElements = [
    selection?.anchorNode?.parentElement?.textContent,
    selection?.anchorNode?.textContent,
  ];
  const selectedWholeElement =
    selectionText && (selectedElements[0] === selectionText || selectedElements[1] === selectionText);
  if (selectedWholeElement) {
    focused = (
      selectionText === selectedElements[0]
        ? selection?.anchorNode?.parentElement
        : selection?.anchorNode
    ) as HTMLElement;
  }

  // There is an existing highlight that doesn't span the whole header
  if (highlightContents && focused && highlightContents !== focused?.textContent) {
    focused = null;
  }

  // Select whole header if:
  //   1. Selected whole header
  //   2. Existing highlight spans whole header
  //   3. No highlight but focus indicator is on the header
  if ((!selectionText || selectedWholeElement) && focused && focused.tagName.startsWith('H')) {
    let elements = Array.from(elementRef.current.children) as HTMLElement[];
    // This can happen because occasionally the document is wrapped in a few
    // <div> tags. There are likely other cases that aren't handled.
    while (!elements.includes(focused) && elements.length === 1) {
      elements = Array.from(elements[0].children) as HTMLElement[];
    }
    const focusedIndex = elements.indexOf(focused);
    if (focusedIndex === -1) {
      // We somehow couldn't find it
      return highlight.markdown;
    }
    const focusedChildren = [elements[focusedIndex]];
    const focusedHeaderLevel = parseInt(focused.tagName[1], 10);

    for (let i = focusedIndex + 1; i < elements.length; i++) {
      const isHeader = elements[i].tagName.startsWith('H');
      if (isHeader) {
        // h2 -> h2 or h1
        const headerLevel = parseInt(elements[i].tagName[1], 10);
        if (headerLevel <= focusedHeaderLevel) {
          break;
        }
      }
      focusedChildren.push(elements[i]);
    }

    const selectionInfo = await contentFrame.getSelectionInfoFromElements({ elements: focusedChildren });
    return selectionInfo?.markdown;
  }

  return highlight.markdown;
};
