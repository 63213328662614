// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useCallback, useEffect, useMemo } from 'react';
import forwardRef from 'shared/foreground/utils/forwardRef';
import getCmdOrCtrl from 'shared/foreground/utils/getCmdOrCtrl';
import type { FirstClassDocument, Highlight, PartialDocument } from 'shared/types';
import type { GlobalTagsObject } from 'shared/types/tags';
import { isHTMLElement } from 'shared/typeValidators';

import useIsFocused from '../../hooks/useIsFocused';
import EditTagsForm, { RefValue as EditTagsFormRefValue } from '../EditTagsForm';
import styles from './EditTagsPopover.module.css';
import Popover, { Props as PopoverProps } from './Popover';

type Props = {
  className?: string;
  doc:
    | FirstClassDocument
    | Highlight
    | null
    | void
    | PartialDocument<FirstClassDocument, 'tags' | 'id' | 'category'>;
  globalTagsObject: GlobalTagsObject;
  onChange?: () => void;
  shouldShowInMargin?: boolean;
} & Pick<
  PopoverProps,
  | 'allowFlip'
  | 'getBoundingClientRect'
  | 'hidePopover'
  | 'isShown'
  | 'onClick'
  | 'onHiddenWithEscape'
  | 'popperOptions'
  | 'portalDestinationElementId'
  | 'positionUpdateCounter'
  | 'reference'
  | 'shouldStayInDomWhenHidden'
  | 'showPopover'
>;

export default React.memo(
  forwardRef<Props, EditTagsFormRefValue>(function EditTagsPopover(
    {
      allowFlip,
      className,
      doc,
      getBoundingClientRect,
      globalTagsObject,
      hidePopover,
      isShown,
      onHiddenWithEscape,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onChange = () => {},
      onClick,
      popperOptions,
      portalDestinationElementId,
      reference,
      shouldShowInMargin,
      shouldStayInDomWhenHidden,
      showPopover,
      ...otherProps
    },
    ref,
  ) {
    const currentTags = useMemo(() => Object.values(doc?.tags ?? {}), [doc?.tags]);
    const shouldBeKeptOpen = useMemo(
      () => Object.keys(currentTags).length && shouldShowInMargin,
      [currentTags, shouldShowInMargin],
    );

    useEffect(() => {
      if (shouldBeKeptOpen) {
        showPopover?.();
      }
    }, [shouldBeKeptOpen, showPopover]);

    const { isFocused, onBlur, onFocus } = useIsFocused();

    const onKeyDown = useCallback((event: React.KeyboardEvent) => {
      event.stopPropagation();
    }, []);

    const onTagsFormKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Escape' && shouldBeKeptOpen) {
          if (isHTMLElement(document.activeElement)) {
            document.activeElement.blur();
          }
        }
      },
      [shouldBeKeptOpen],
    );

    const onOptionSelected = useCallback(
      (details: {
        interaction: 'key' | 'mouse';
        keysPressed: {
          cmd: boolean;
          ctrl: boolean;
        };
      }) => {
        if (shouldBeKeptOpen || details.interaction === 'mouse') {
          return;
        }

        // cmd/ctrl+enter selects + keeps it open
        if (!details.keysPressed[getCmdOrCtrl()]) {
          hidePopover();
        }
      },
      [hidePopover, shouldBeKeptOpen],
    );

    const isInReadOnlyMode = shouldShowInMargin && !isFocused;

    const rootClasses = [styles.root];
    if (isInReadOnlyMode) {
      rootClasses.push(styles.rootInReadOnlyMode);
    }
    if (className) {
      rootClasses.push(className);
    }

    // popover clears any text the user has entered when clicking anywhere in the tags search bar, this prevents it
    const onMouseDown: React.MouseEventHandler<HTMLSpanElement> = useCallback((event) => {
      event.preventDefault();
    }, []);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (
      <Popover
        allowFlip={allowFlip}
        className={rootClasses.join(' ')}
        getBoundingClientRect={getBoundingClientRect}
        hidePopover={hidePopover}
        isShown={isShown}
        onHiddenWithEscape={onHiddenWithEscape}
        onKeyDown={onKeyDown}
        popperOptions={{
          placement: shouldShowInMargin ? 'right-start' : 'bottom',
          ...(popperOptions ?? {}),
        }}
        portalDestinationElementId={portalDestinationElementId}
        onClick={onClick}
        selectorToAutoFocus="input"
        shouldAutoFocus={!shouldShowInMargin}
        shouldHideOnBlur={false}
        shouldHideOnClickOutside={!shouldBeKeptOpen}
        shouldHideOnEscape={!shouldBeKeptOpen}
        shouldStayInDomWhenHidden={shouldStayInDomWhenHidden}
        reference={reference}
      >
        <EditTagsForm
          doc={doc}
          globalTagsObject={globalTagsObject}
          isShownInMargin={Boolean(shouldShowInMargin)}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          onMouseDown={onMouseDown}
          onKeyDown={onTagsFormKeyDown}
          onOptionSelected={onOptionSelected}
          ref={ref}
          {...otherProps}
        />
      </Popover>
    );
  } as React.FC<Props>),
);
