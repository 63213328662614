import React, { useCallback } from 'react';
import { globalState } from 'shared/foreground/models';
import { ShortcutId } from 'shared/types/keyboardShortcuts';

import { hideDesktopNavigationSidebar } from '../stateUpdaters/sidebars';
import { useShortcutsMap } from '../utils/shortcuts';
import Button from './Button';
import StrokeToggleHideNavigationMenuIcon from './icons/20StrokeToggleHideNavigationMenuIcon';
import styles from './ShowNavigationLeftPanelButton.module.css';
import Tooltip from './Tooltip';

export const ShowNavigationLeftPanelButton = ({ className = '' }: { className?: string }) => {
  const shortcutsMap = useShortcutsMap();
  const navigationSidebarIsHidden = globalState(
    useCallback((state) => state.client.navigationSidebar.isWebNavigationSidebarHidden, []),
  );

  if (!navigationSidebarIsHidden) {
    return null;
  }

  return (
    <Tooltip
      content="Show left panel"
      placement="right"
      shortcut={shortcutsMap[ShortcutId.HideLeftPanel]}
    >
      <Button
        variant="unstyled"
        className={`${styles.hideNavigationBarButton} ${className}`}
        onClick={() => hideDesktopNavigationSidebar(false)}
      >
        <StrokeToggleHideNavigationMenuIcon text="Show left panel" />
      </Button>
    </Tooltip>
  );
};
