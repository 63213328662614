import getSplitByDefaultValue from 'shared/foreground/utils/getSplitByDefaultValue';
import { FilteredView, SettingsState } from 'shared/types';
import urlJoin from 'shared/utils/urlJoin';

import fixedEncodeURIComponent from './fixedEncodeURIComponent';

export const getFilteredViewPath = (
  view: FilteredView,
  documentLocations: SettingsState['documentLocations'],
) => {
  if (!view.query) {
    return `/rss-folder/${view.id}`;
  }

  const basePath = urlJoin(['/filter', fixedEncodeURIComponent(view.query)]);
  if (view.splitBy) {
    const splitByDefaultValue = getSplitByDefaultValue(view.splitBy as string, documentLocations);
    const splitByUrl = splitByDefaultValue ? urlJoin(['/split', view.splitBy, splitByDefaultValue]) : '';
    return urlJoin([basePath, splitByUrl]);
  }

  return basePath;
};
