import { Category } from '../../types';
import { getEnhancedYouTubeTranscript } from '../getEnhancedYouTubeTranscript';
import { rwSanitizeDefault } from './default';
import { rwSanitizeForEPUBs } from './forEPUBs';
import { rwSanitizeForOriginalEmails } from './forOriginalEmails';

export function rwSanitizeHtml(
  content: string,
  category: Category,
  isOriginalEmailView?: boolean,
  showEnhancedYouTubeTranscript?: boolean,
) {
  if (isOriginalEmailView) {
    return rwSanitizeForOriginalEmails(content, category);
  }
  if (category === Category.EPUB) {
    return rwSanitizeForEPUBs(content, category);
  }

  if (showEnhancedYouTubeTranscript) {
    const enhancedYouTubeTranscript = getEnhancedYouTubeTranscript(content);
    if (enhancedYouTubeTranscript) {
      return rwSanitizeDefault(enhancedYouTubeTranscript, category);
    }
  }

  return rwSanitizeDefault(content, category);
}
