import { useCallback, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { globalState } from 'shared/foreground/models';
import { isNarrowScreenSize } from 'shared/foreground/stateHooks';

import { NOTEBOOK_PATH_PATTERN } from '../components/NotebookView/constants';
import type { NotebookRouteParams } from '../components/NotebookView/NotebookPage';
import { isNotebookViewPath, isReaderViewUrl } from '../utils/pathnameHelpers';
import useLocation from '../utils/useLocation';

export const useScrollToTop = (element: HTMLElement | null): void => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (element) {
      element.scrollTo(0, 0);
    }
  }, [pathname, element]);
};

export const useIsLeftSidebarHidden = (): boolean => {
  const { pathname } = useLocation();
  const screenWidth = globalState(useCallback((state) => state.screenWidth, []));
  const hiddenInReadingView = globalState(
    useCallback((state) => state.leftSidebarHiddenInReadingView, []),
  );
  const leftSidebarHidden =
    isReaderViewUrl(pathname) || isNotebookViewPath(pathname) ? hiddenInReadingView : false;
  const leftSidebarHiddenForNarrowScreen = globalState(
    useCallback((state) => state.leftSidebarHiddenForNarrowScreen, []),
  );
  return isNarrowScreenSize(screenWidth) ? leftSidebarHiddenForNarrowScreen : leftSidebarHidden;
};

export const useIsRightSidebarHidden = (): boolean => {
  const { pathname } = useLocation();
  const screenWidth = globalState(useCallback((state) => state.screenWidth, []));
  const hiddenInReadingView = globalState(
    useCallback((state) => state.rightSidebarHiddenInReadingView, []),
  );
  const hiddenInList = globalState(useCallback((state) => state.client.rightSidebarHiddenInList, []));
  const rightSidebarHidden =
    isReaderViewUrl(pathname) || isNotebookViewPath(pathname) ? hiddenInReadingView : hiddenInList;
  const rightSidebarHiddenForNarrowScreen = globalState(
    useCallback((state) => state.rightSidebarHiddenForNarrowScreen, []),
  );
  const pathsToAlwaysHideRightSidebar = [
    '/account',
    '/database-explorer',
    '/database-query-optimizer',
    '/feed/sources',
    '/add-to-library',
    '/add-to-feed',
    '/resources',
    '/integrations',
    '/preferences',
    '/product-emails',
    '/profile',
    '/tags',
    '/views',
  ];

  const shouldHideRightSidebar = pathsToAlwaysHideRightSidebar.some((path) => pathname.startsWith(path));

  if (shouldHideRightSidebar) {
    return true;
  }

  return isNarrowScreenSize(screenWidth) ? rightSidebarHiddenForNarrowScreen : rightSidebarHidden;
};

export const useSidebarsHidden = (): boolean => {
  const leftSidebarHidden = useIsLeftSidebarHidden();
  const rightSidebarHidden = useIsRightSidebarHidden();
  return leftSidebarHidden && rightSidebarHidden;
};

export const useIsNavigationSidebarHidden = (): boolean => {
  return globalState(
    useCallback((state) => state.client.navigationSidebar.isWebNavigationSidebarHidden, []),
  );
};

export function useNotebookViewParams(): NotebookRouteParams | null {
  return useRouteMatch<NotebookRouteParams>(NOTEBOOK_PATH_PATTERN)?.params ?? null;
}
