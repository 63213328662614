import sanitizeHtml from 'sanitize-html';

import { Category } from '../../types';
import {
  allowVulnerableTags,
  defaultAllowedClasses,
  getDefaultAllowedAttributes,
  getDefaultAllowedTags,
  getDefaultTransformTags,
} from './common';

export function rwSanitizeDefault(content: string, category: Category) {
  // we keep inline styles for images in emails, as sometimes they're needed to properly size the images.
  // TODO: the parser could transform the inline widths/heights to width/height dom properties so we could avoid this
  const allowImageStyles = category === Category.Email;
  const allowedTags = getDefaultAllowedTags();
  const allowedAllAttrs: string[] = ['data-rw-*', 'id'];
  const allowedClasses = defaultAllowedClasses;
  const defaultTransformTags = getDefaultTransformTags(allowImageStyles, category);
  return sanitizeHtml(content, {
    allowedAttributes: getDefaultAllowedAttributes(allowedAllAttrs, allowImageStyles),
    allowedClasses,
    allowedTags,
    // For Style tag warning (style tag is considered dangerous)
    allowVulnerableTags,
    transformTags: defaultTransformTags,
  });
}
