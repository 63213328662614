import urlJoin from 'shared/utils/urlJoin';

export const getReadingUrlForDocument = ({
  documentPathPrefix,
  docId,
}: {
  documentPathPrefix?: string;
  docId: string | null;
}) => {
  // In home screen the documentPathPrefix is empty
  return documentPathPrefix ? urlJoin([documentPathPrefix, 'read', docId]) : urlJoin(['/read', docId]);
};
