import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MangoQuery } from 'rxdb';
import { showModal } from 'shared/foreground/actions/modals';
import { openFeedsSubMenu } from 'shared/foreground/cmdPalette';
import { AnyDocument } from 'shared/types';
import { ShortcutId } from 'shared/types/keyboardShortcuts';

import { useShortcutsMap } from '../../utils/shortcuts';
import Ellipsis from '../icons/16StrokeEllipsis';
import { modalId as addRssFolderModalId } from '../Modals/AddRssFolderModal';
import styles from './BuiltInCategoryViewDropDown.module.css';
import { getSeparatorOption } from './docOptions';
import { Dropdown, DropdownOptionType } from './Dropdown';
import { getMarkAllAsSeenOption, getOpenBulkActionsOption } from './DropdownMainHeader';

type GetFeedSectionDropdownOptionsProps = {
  documentIdsQuery?: MangoQuery<AnyDocument>;
  shortcutsMap: ReturnType<typeof useShortcutsMap>;
  history: ReturnType<typeof useHistory>;
  isInSidebar?: boolean;
};

export const getFeedSectionDropdownOptions = ({
  documentIdsQuery,
  shortcutsMap,
  history,
  isInSidebar = false,
}: GetFeedSectionDropdownOptionsProps) => {
  return [
    {
      type: DropdownOptionType.Item,
      name: 'Subscribe to RSS feed',
      onSelect: openFeedsSubMenu,
    },
    {
      type: DropdownOptionType.Item,
      name: 'Add new RSS folder',
      onSelect: () => showModal({ id: addRssFolderModalId }, { userInteraction: 'click' }),
    },
    getSeparatorOption(),
    getMarkAllAsSeenOption({ documentIdsQuery }),
    getOpenBulkActionsOption({
      isDisabled: isInSidebar,
      shortcut: shortcutsMap[ShortcutId.OpenBulkActionsSubMenu],
    }),
    getSeparatorOption(),
    {
      type: DropdownOptionType.Item,
      name: 'Manage feeds',
      onSelect: () => history.push('/feed/sources'),
    },
  ];
};

const Trigger = () => {
  return (
    <span>
      <Ellipsis />
    </span>
  );
};

export default React.memo(function FeedSectionDropDown({
  onOpenChange,
  getViewMangoQueryWithoutSort,
}: {
  onOpenChange?: (isOpen: boolean) => void;
  getViewMangoQueryWithoutSort: () => MangoQuery<AnyDocument> | undefined;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const shortcutsMap = useShortcutsMap();

  const TriggerElement = (
    <DropdownMenu.Trigger className={`${styles.dropdownTrigger} ${isOpen ? styles.isOpen : ''}`}>
      <Trigger />
    </DropdownMenu.Trigger>
  );

  const options = useMemo(() => {
    return getFeedSectionDropdownOptions({
      documentIdsQuery: getViewMangoQueryWithoutSort(),
      shortcutsMap,
      isInSidebar: true,
      history,
    });
  }, [getViewMangoQueryWithoutSort, shortcutsMap, history]);

  // Appending a lot of dropdowns to the body makes the transition to this view
  // a bit slow. So we are going to append it only after the user actually clicks it.

  if (isOpen) {
    return (
      <Dropdown
        trigger={TriggerElement}
        options={options}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onOpenChange={onOpenChange}
        contentClassName={styles.dropdownContent}
        appendToDocumentBody
      />
    );
  }

  return (
    <button
      type="button"
      className={styles.dropdownTrigger}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onOpenChange?.(true);
        setIsOpen(true);
      }}
    >
      <Trigger />
    </button>
  );
});
