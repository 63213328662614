import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React, { useCallback } from 'react';
import {
  setDocumentsSortMenuOpen,
  updateFilteredOrRegularSortRule,
} from 'shared/foreground/documentsSortMenu';
import { globalState, SortKeyDisplayName, SortOrderDisplayName } from 'shared/foreground/models';
import { useSortRules } from 'shared/foreground/stateHooks';
import type { FilteredView, SortRule } from 'shared/types';
import { SortKey, SortOrder } from 'shared/types';

import { getSeparatorOption, getTitleOption } from '../Dropdown/docOptions';
import { Dropdown, DropdownOption, DropdownOptionType } from '../Dropdown/Dropdown';
import ChevronDown from '../icons/ChevronDownSmallIcon';
import SortDownIcon from '../icons/SortDownIcon';
import SortIcon from '../icons/SortIcon';
import SortUpIcon from '../icons/SortUpIcon';
import styles from './SortView.module.css';

export function SortButton({
  currentFilteredView,
  listId,
}: { currentFilteredView?: FilteredView; listId: string }): JSX.Element {
  const isOpen = globalState(useCallback((state) => state.isDocumentsSortMenuShown, []));
  const rules = useSortRules({ filteredView: currentFilteredView, listId });

  const currentSortRule = rules[0] as SortRule;
  const orderIcon = currentSortRule.order === SortOrder.Asc ? <SortUpIcon /> : <SortDownIcon />;
  const icon = isOpen ? <SortIcon /> : orderIcon;

  const onSortRuleChange = async (updatedSortRule: SortRule) => {
    setDocumentsSortMenuOpen(false, { userInteraction: 'click' });
    updateFilteredOrRegularSortRule({
      listId,
      updatedSortRule,
      currentFilteredView,
      options: { userInteraction: 'click' },
    });
  };

  const triggerElement = (
    <DropdownMenu.Trigger asChild>
      <button type="button" className={`${styles.sortButton} ${isOpen ? styles.opened : ''}`}>
        {icon}
        {SortKeyDisplayName[currentSortRule.key]}
        <ChevronDown className={`${styles.chevronIcon} ${isOpen ? styles.opened : ''}`} />
      </button>
    </DropdownMenu.Trigger>
  );

  const sortKeysOptions: DropdownOption[] = Object.values(SortKey).map((key) => ({
    type: DropdownOptionType.Item,
    name: SortKeyDisplayName[key],
    checked: currentSortRule.key === key,
    onSelect: () => {
      onSortRuleChange({ ...currentSortRule, key });
    },
  }));

  const options: DropdownOption[] = [getTitleOption('Sort by'), ...sortKeysOptions];

  if (currentSortRule.key !== SortKey.Random) {
    const sortOrderOptions: DropdownOption[] = Object.values(SortOrder).map((order) => {
      return {
        type: DropdownOptionType.Item,
        name: SortOrderDisplayName[currentSortRule.key][order],
        checked: currentSortRule.order === order,
        onSelect: () => {
          onSortRuleChange({ ...currentSortRule, order });
        },
      };
    });

    options.push(getSeparatorOption(), getTitleOption('Order by'), ...sortOrderOptions);
  }

  return (
    <Dropdown
      trigger={triggerElement}
      options={options}
      isOpen={isOpen}
      setIsOpen={(newIsOpen) => setDocumentsSortMenuOpen(newIsOpen, { userInteraction: 'click' })}
      zIndexOverride={103}
    />
  );
}
