import { parseFromTokens } from 'shared/filters-compiler/parser';
import { tokenizer } from 'shared/filters-compiler/tokenizer';
import { safeDecodeURIComponent } from 'shared/utils/safeDecodeURIComponent';

export const isValidQuery = (query: string): boolean => {
  const decodedQuery = safeDecodeURIComponent(query);
  const { tokens: filterTokens, errorMessage: tokenizerErrorMessage } = tokenizer(decodedQuery);
  const { errorMessage: parserErrorMessage } = parseFromTokens(filterTokens ?? []);

  return !tokenizerErrorMessage && !parserErrorMessage;
};
