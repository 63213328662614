import { JobType } from '../../../../types';
import background from '../../../portalGates/toBackground';
import { StateUpdateOptionsWithoutEventName } from '../../../types';
import { queueJob } from '../jobs';
import { updateDocument } from './update';

export const toggleVideoOriginalTranscript = async (
  documentId: string,
  options: StateUpdateOptionsWithoutEventName,
): Promise<void> => {
  let needsToTriggerEnhanceYouTubeTranscriptJob = false;

  await updateDocument(
    documentId,
    (doc) => {
      if (!doc?.source_specific_data) {
        doc.source_specific_data = {};
      }

      if (!doc?.source_specific_data?.video) {
        doc.source_specific_data.video = {};
      }

      const willShowEnhancedYoutubeTranscript =
        !doc.source_specific_data.video.show_enhanced_youtube_transcript;

      doc.source_specific_data.video.show_enhanced_youtube_transcript =
        willShowEnhancedYoutubeTranscript;

      if (
        willShowEnhancedYoutubeTranscript &&
        !doc.source_specific_data.video.enhanced_youtube_transcript_status
      ) {
        needsToTriggerEnhanceYouTubeTranscriptJob = true;
        doc.source_specific_data.video.enhanced_youtube_transcript_status = 'pending';
      }
    },
    { ...options, eventName: 'video-original-transcript-toggled' },
  );

  if (!needsToTriggerEnhanceYouTubeTranscriptJob) {
    return;
  }

  queueJob({
    jobType: JobType.EnhanceYouTubeTranscript,
    jobArguments: { documentId },
    options: { ...options },
  });

  background.pollLatestState(20);
};
