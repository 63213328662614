import React, { useEffect, useState } from 'react';
import foregroundEventEmitter from 'shared/foreground/eventEmitter';
import { RightSidebarVisiblePanel } from 'shared/types';
import pluralize from 'shared/utils/pluralize';

import { hideRightSidebar } from '../stateUpdaters/sidebars';
import Button from './Button';
import styles from './DocumentTextContent.module.css';
import CloseIcon from './icons/CloseIcon';

export function FailedToRenderHighlightsBanner({
  highlightIds,
  className,
  onVisibleChange,
  text,
}: {
  highlightIds: string[];
  className?: string;
  onVisibleChange?: (visible: boolean) => void;
  text?: string;
}) {
  const [isVisible, setIsVisible] = useState(true);
  const pronoun = highlightIds.length === 1 ? 'it' : 'them';
  const amount = highlightIds.length === 1 ? 'a' : highlightIds.length;

  useEffect(() => {
    if (onVisibleChange) {
      onVisibleChange(isVisible);
    }
  }, [isVisible, onVisibleChange]);

  const onClick = () => {
    hideRightSidebar(false, { userInteraction: 'click' });
    foregroundEventEmitter.emit(
      'document-sidebar:setVisiblePanel',
      RightSidebarVisiblePanel.DocumentNotebook,
    );
    foregroundEventEmitter.emit('document-sidebar:scrollToHighlight', {
      id: highlightIds[0],
    });
  };

  if (!isVisible || highlightIds.length === 0) {
    return null;
  }

  return (
    <p className={`${styles.highlightsFailedToRenderMessage} ${className}`}>
      <span>
        {text ??
          `You made ${amount} ${pluralize('highlight', highlightIds.length)} on the original page that we
        aren't able to overlay here.`}
      </span>
      <Button
        className={styles.highlightsFailedToRenderMessageButton}
        onClick={onClick}
        variant="default"
      >
        View {pronoun}
      </Button>
      <Button onClick={() => setIsVisible(false)} className={styles.highlightsFailedToRenderCloseButton}>
        <CloseIcon />
      </Button>
    </p>
  );
}
